.brand-navigation {
  background-image: url('../../assets/images/holmasto-preheader.jpeg');
  background-position: bottom;
  background-repeat: repeat;
  color: var(--color-black);
  min-height: 24px;
  padding-left: calc(var(--gds-spacing-l) - var(--gds-spacing-s));
  padding-right: calc(var(--gds-spacing-l) - var(--gds-spacing-s));
}
gds-navigation {
  --submenu-background-color: #f2f2f2;
  --gds-menu-item-font-family: var(--font-family-primary);
  --gds-body-font-family: var(--font-family-primary);
  --gds-menu-item-font-weight: 700;
}
gds-navigation #gds-navigation-content-1 {
  display: flex;
  flex-direction: column;
}
gds-navigation #gds-navigation-content-1 .gds-navigation-mobile-extensions {
  order: 1;
}
gds-navigation #gds-navigation-content-1 .gds-navigation-mobile-extensions gds-menu {
  display: flex;
  flex-direction: row;
  gap: 24px;
}
gds-navigation #gds-navigation-content-1 .gds-navigation-nav {
  order: 2;
}
@media (min-width: 1024px) {
  gds-navigation {
    --gds-navigation-logo-height: 44px;
    --gds-navigation-desktop-height: 72px;
 }
  gds-navigation .gds-navigation-container {
    grid-gap: var(--gds-spacing-l);
    display: flex;
    padding: var(--gds-spacing-m);
 }
  gds-navigation .gds-navigation-logo {
    margin-left: 48px;
    flex-grow: 0;
    height: var(--gds-navigation-logo-height);
 }
  gds-navigation gds-menu {
    letter-spacing: 0.8px;
 }
  gds-navigation gds-menu-item {
    padding: 8px 12px;
    letter-spacing: inherit;
    cursor: pointer;
 }
  gds-navigation gds-submenu {
    background-color: var(--submenu-background-color);
    box-shadow: none;
 }
}
