@font-face {
  font-family: 'Atten New';
  src: local('Atten New'), local('Atten New'),
  url(./assets/fonts/AttenNew-Regular.woff2) format('woff2'),
  url(./assets/fonts/AttenNew-Regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Atten New';
  src: local('Atten New'), local('Atten New'),
  url(./assets/fonts/AttenNew-Bold.woff2) format('woff2'),
  url(./assets/fonts/AttenNew-Bold.woff) format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Atten New';
  src: local('Atten New'), local('Atten New'),
  url(./assets/fonts/AttenNew-Medium.woff2) format('woff2'),
  url(./assets/fonts/AttenNew-Medium.woff) format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Kepler Std';
  src: local('Kepler Std'), local('Kepler Std'),
  url(./assets/fonts/KeplerStd-Regular.woff2) format('woff2'),
  url(./assets/fonts/KeplerStd-Regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Kepler Std';
  src: local('Kepler Std'), local('Kepler Std'),
  url(./assets/fonts/KeplerStd-Medium.woff2) format('woff2'),
  url(./assets/fonts/KeplerStd-Medium.woff) format('woff');
  font-weight: 500;
  font-style: normal;
}